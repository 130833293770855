












































































































































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';
import APISTATE from '@/enums/APISTATE';
import { ProjectsDto } from '@/models/planning/ProjectsDto';
import { DepartmentDto } from '@/models/planning/DepartmentDto';
import { SchoolTermDto } from '@/models/planning/SchoolTermDto';
import { ProjectTypeDto } from '@/models/planning/ProjectTypeDto';
import { FinancedDto } from '@/models/ConstantsDto';

export default Vue.extend({
  name: 'project-view',
  props: {
    projectId: {
      type: String,
      required: false
    }
  },
  data: () => ({
    deleting: false,
    form: {} as ProjectsDto,
    
  }),
  async mounted() {
    await this.loadDepartments();
    await this.loadSchoolTerms();
    await this.loadProjectTypes();
    await this.loadProject(this.projectId);

    this.assignCriteria([
      { key: 'relDepartment', validation:() => !!this.form.relDepartment, message: "Skal udfyldes" },
      { key: 'relSchoolTerm', validation:() => !!this.form.relSchoolTerm, message: "Skal udfyldes" },
      { key: 'relProjectType', validation:() => !!this.form.relProjectType, message: "Skal udfyldes" },
      { key: 'projectId', validation:() => this.form.projectId?.length > 0, message: "Skal udfyldes" },
      { key: 'isFinanced', validation:() => [true,false].includes(this.form.isFinanced), message: "Skal udfyldes" },
      { key: 'numberOfPlanningHours', validation:() => this.form?.numberOfPlanningHours > 0, message: "Den indtastede værdi skal være større end 0" }
    ]);
  },
  methods: {
    ...mapActions('projectsStore', ['createProject', 'updateProject', 'loadProject', 'removeProject']),
    ...mapActions('departments', ['loadDepartments']),
    ...mapActions('schoolTermStore', ['loadSchoolTerms']),
    ...mapActions('projectTypeStore', ['loadProjectTypes']),
    ...mapActions('validationStore', ['assignCriteria', 'validate']),
    async remove(controls) {
      try {
        await this.removeProject(this.projectId);
        controls.close();
        this.$router.push({name: 'Projects'});
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved slet af opgave.',
          type: 'is-danger'
        });
      }
    },
    async create() {
      try {
        if (await this.validate()) {
          await this.createProject(this.form);
          this.$router.push({name: 'Projects'});
        }
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved opret af opgave.',
          type: 'is-danger'
        });
      }
    },
    async update() {
      try {
        if (await this.validate()) {
          await this.updateProject(this.form);
          this.$router.push({name: 'Projects'});
        }
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved ændring af opgave.',
          type: 'is-danger'
        });
      }
    }

  },
  computed: {
    ...mapGetters('projectsStore', ['project', 'projectsApiState']),
    ...mapGetters('departments', {departments: 'departments', isLoadingDepartments: 'isLoading'}),
    ...mapGetters('schoolTermStore', ['schoolTerms', 'schoolTermApiState']),
    ...mapGetters('projectTypeStore', ['projectTypes']),
    ...mapGetters('constantsStore', ['financedOptions']),
    ...mapGetters('validationStore', ['isValid', 'validationMessage']),
    loading() {
      return this.isLoadingDepartments
        || this.isLoadingSchoolTerms
        || this.projectsApiState === APISTATE.LOADING;
    },
    isLoadingProjectTypes() {
      return false;
    },
    isLoadingSchoolTerms() {
      return this.schoolTermApiState === APISTATE.LOADING;
    },
    newItem() {
      return !this.projectId;
    },
    tabs() {
      if (this.newItem) {
        return [{ title: 'Stamdata', to: {name: 'NewProject'}}];
      }
      return [];
    },
    formRelDepartment: {
      get() {
        return this.departments.find(q => q.id === this.form.relDepartment);
      },
      set(value:DepartmentDto) {
        this.$set(this.form, "relDepartment", value.id);
      }
    },
    formRelSchoolTerm: {
      get() {
        return this.schoolTerms.find(q => q.id === this.form.relSchoolTerm);
      },
      set(value:SchoolTermDto) {
        this.$set(this.form, "relSchoolTerm", value.id);
      }
    },
    formRelProjectType: {
      get() {
        return this.projectTypes.find(q => q.id === this.form.relProjectType);
      },
      set(value:ProjectTypeDto) {
        this.$set(this.form, "relProjectType", value.id);
      }
    },
    formIsFinanced: {
      get() {
        return this.financedOptions.find(q => q.value === this.form.isFinanced);
      },
      set(value:FinancedDto) {
        this.$set(this.form, "isFinanced", value.value);
      }
    }

  },
  watch: {
    'project'(newValue) {
      this.form = {...newValue};

      // Set default value for isFinanced
      if (!Object.prototype.hasOwnProperty.call(this.form, 'isFinanced')) {
        this.form.isFinanced = true;
      }
    }

  }
})
